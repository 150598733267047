<script setup lang="ts">
import {onMounted, onUnmounted, ref} from "vue";
import StoryPopup from "@/ts/Story/StoryPopup";

const props = defineProps<{
  activeUntilOffset?: number
}>()
const show = ref(true);
const hide = () => {
  show.value = false;
  popup.destroy()
  emit('close')
}
const onBack = () => {
  hide()
}
const emit = defineEmits(['close'])
defineExpose({
  hide
})
const container = ref<HTMLElement>()
const popup = new StoryPopup(100, props.activeUntilOffset)
popup.onClose(() => {
  hide()
})
onMounted(() => {
  popup.init(container.value!)
})
onUnmounted(() => {
  popup.destroy()
})
</script>

<template>
  <div class="popup-container" :class="{show}" >
    <div class="popup" :class="{show}" ref="container">
      <slot :hide="hide"/>
    </div>
    <div class="popup-overlay" @click="onBack"></div>
  </div>
</template>

<style scoped lang="scss">
@keyframes fade {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 0);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) scale(1, 1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(-50%) scale(1, 1);
  }
  to {
    opacity: 0;
    transform: translateX(-50%) scale(0, 0);
  }
}

.popup {
  position: fixed;
  max-height: 100%;
  height: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) scale(0, 0);
  box-sizing: border-box;
  width: 100%;
  z-index: 1;
  animation: fadeOut 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
  opacity: 0;
  &.show {
    animation: fade 0.6s ease-in-out;
    opacity: 1;
    transform: translateX(-50%) scale(1, 1);
    &.restoring{
      transition: transform 0.3s ease-in-out;
    }
  }
}
.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0);
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.popup-container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  visibility: hidden;
  transition: visibility 0.6s ease-in-out;
  &.show {
    visibility: visible;
    .popup-overlay {
      opacity: 0.5;
    }
  }
}

</style>
