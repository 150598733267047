<template>
  <div class="content">
    <router-view/>
    <BackgroundAudioPlayer/>
    <ImageViewer />
    <HintsViewer />
    <BlockViewer />
  </div>
</template>

<script setup lang="ts">
import {useHead} from "@vueuse/head";
import BackgroundAudioPlayer from "@/components/UI/AudioPlayer/BackgroundAudioPlayer.vue";
import ImageViewer from "@/components/UI/ImageViewer/ImageViewer.vue";
import HintsViewer from "@/components/Home/Content/HintsViewer.vue";
import BlockViewer from "@/components/Home/Content/BlockViewer.vue";

useHead({
  title: 'Magic moon'
})
</script>

