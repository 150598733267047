export default class HintsViewer {

    private container: null | HTMLElement = null as HTMLElement | null;
    private static instance: HintsViewer;
    private static creating: boolean = false;
    private onShowListeners: (() => void)[] = [];
    private readonly localStorageKey = 'hintsViewerShown';

    public isShown(): boolean {
        return localStorage.getItem(this.localStorageKey) === 'true';
    }

    static getInstance(): HintsViewer {
        if (!HintsViewer.instance) {
            HintsViewer.creating = true;
            HintsViewer.instance = new HintsViewer();
            HintsViewer.creating = false;
        }
        return HintsViewer.instance;
    }

    public addOnShowListener(listener: () => void) {
        this.onShowListeners.push(listener);
    }

    public init(container: HTMLElement) {
        this.container = container;
    }

    public showFirstTime() {
        if (!this.isShown()) {
            this.show();
        }
    }

    public show() {
        this.onShowListeners.forEach(listener => listener());
        localStorage.setItem(this.localStorageKey, 'true');
    }

    public destroy() {

    }
}
