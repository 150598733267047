<script setup lang="ts">
import StoryPopup from "@/components/Home/Content/Story/StoryPopup.vue";
import {nextTick, onMounted, onUnmounted, ref} from "vue";
import HintsViewer from "@/ts/User/HintsViewer";
import CloseButton from "@/components/UI/Button/CloseButton.vue";
import Icon from "@/components/UI/Icon.vue";
import 'swiper/css';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from "swiper/vue";
import {Pagination} from 'swiper/modules';
import type {PaginationOptions} from "swiper/types";
import HintRepository from "@/ts/User/Repository/HintRepository";
import CDN from "@/ts/Utility/CDN";

const container = ref<HTMLDivElement>()
const popup = ref<StoryPopup>()

const showedPopup = ref(false)
let viewer: HintsViewer;
const slides = ref<string[]>([])

const loadSlides = async () => {
  slides.value = (await HintRepository.getAll()).map(hint => hint.image)
}

onMounted(() => {
  const viewer = HintsViewer.getInstance()
  viewer.addOnShowListener(async () => {
    loadSlides().then()
    container.value!.classList.add('active')
    showedPopup.value = false
    await nextTick()
    showedPopup.value = true
  })
  viewer.init(container.value!)
})
const onClose = () => {
  setTimeout(() => {
    showedPopup.value = false
  }, 500)
}
onUnmounted(() => {
  showedPopup.value = false
  viewer?.destroy()
})
const modules = [Pagination]
const pagination: PaginationOptions = {
  el: '.hints-viewer__container__footer',
}
</script>

<template>
  <div ref="container" class="hints-viewer">
    <StoryPopup ref="popup" @close="onClose" v-if="showedPopup" :activeUntilOffset="100" v-slot="{hide}">
      <div class="hints-viewer__container">
        <div class="hints-viewer__container__header">
          <div class="hints-viewer__container__header__left">
            <Icon name="zipper" color="#FD9F30" :size="14"/>
            Подсказки
          </div>
          <div class="hints-viewer__container__header__right">
            <CloseButton theme="dark" @click="hide"/>
          </div>
        </div>
        <div class="hints-viewer__container__content">
          <Swiper :pagination="pagination"
                  :spaceBetween="20"
                  :modules="modules"
                  class="hints-viewer__slider__swiper">
            <SwiperSlide v-for="slide in slides">
              <picture class="hints-viewer__slider__image">
                <img :src="CDN.createUrl(slide)"/>
              </picture>
            </SwiperSlide>
          </Swiper>
        </div>
        <div class="hints-viewer__container__footer">
        </div>
      </div>
    </StoryPopup>
  </div>
</template>

<style scoped lang="scss">
.hints-viewer {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #232529;
    padding: 30px 20px 20px 20px;
    box-sizing: border-box;
    gap: 15px;

    &__header {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &__left {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        gap: 6px;
        color: #FD9F30;
      }
    }

    &__content {
      overflow: hidden;
    }

    &__footer {
      text-align: center;
      --swiper-pagination-bullet-size: 10px;
      --swiper-pagination-color: #FD9F30;
      --swiper-pagination-bullet-inactive-opacity: 1;
      --swiper-pagination-bullet-inactive-color: #EFEFEF;
    }
  }

  &__slider {
    &__swiper {
      aspect-ratio: 340 / 586;
      max-height: 100%;

      :deep(.swiper-slide) {
        height: auto;
        aspect-ratio: 340/586;
      }
    }

    &__image {
      border-radius: 30px;
      overflow: hidden;
      display: flex;
      width: 100%;
      height: auto;
      aspect-ratio: 340/586;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
