<script setup lang="ts">

import Popup from "@/ts/Popup/Popup";
import {ref, watchEffect} from "vue";

type Theme = 'light' | 'dark';

defineProps({
  theme: {
    type: String as () => Theme,
    default: 'light'
  }
});
const popupStyle = ref({})
const container = ref<HTMLElement>()
const show = defineModel('show')
const popup = new Popup(100, 80, popupStyle)
const close = () => {
  show.value = false;
}
popup.onClose(() => {
  show.value = false;
})
watchEffect(() => {
  if (show.value) {
    if (container.value) {
      popup.init(container.value)
    }
  } else {
    popup.destroy()
  }
})
</script>

<template>
  <div class="popup-container" :class="{show}" :style="popupStyle">
    <div class="popup" :class="{show, [theme]: true}" ref="container">
      <slot/>
    </div>
    <div class="popup-overlay" @click="close"></div>
  </div>
</template>

<style scoped lang="scss">

@keyframes fade {
  from {
    transform: translateX(-50%) translateY(100%);
  }
  to {
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes fadeOut {
  from {
    transform: translateX(-50%) translateY(var(--start-translate-y, 0));
  }
  to {
    transform: translateX(-50%) translateY(100%);
  }
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0);
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.popup-container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  visibility: hidden;
  transition: visibility 0.6s ease-in-out;

  &.show {
    visibility: visible;

    .popup-overlay {
      opacity: 0.5;
    }
  }
}


.popup {
  background-color: #FFFFFF;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: fixed;
  max-height: calc(100% - 54px);
  height: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  box-sizing: border-box;
  width: 100%;
  z-index: 1;
  animation: fadeOut 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.dark {
    background-color: #232529;

    .popup__content {
      color: #F5F5F5;
    }
  }

  &.light {
    background-color: #FFFFFF;
  }

  &.show {
    animation: fade 0.6s ease-in-out;
    transform: translateX(-50%) translateY(0);

    &.restoring {
      transition: transform 0.3s ease-in-out;
    }
  }
}
</style>
