<script setup lang="ts">
import StoryPopup from "@/components/Home/Content/Story/StoryPopup.vue";
import {Component, nextTick, onMounted, onUnmounted, ref} from "vue";
import 'swiper/css';
import 'swiper/css/pagination';
import BlockViewer from "@/ts/User/BlockViewer";
const container = ref<HTMLDivElement>()
const popup = ref<StoryPopup>()

const showedPopup = ref(false)
const blockComponent = ref<Component | null>(null)
const blockProps = ref<Record<string, any>>({})
let viewer: BlockViewer;

onMounted(() => {
  const viewer = BlockViewer.getInstance()
  viewer.addOnShowListener(async () => {
    container.value!.classList.add('active')
    showedPopup.value = false
    await nextTick()
    showedPopup.value = true
  })
  viewer.init(container.value!, blockComponent, blockProps)
})
const onClose = () => {
  setTimeout(() => {
    showedPopup.value = false
  }, 500)
}
onUnmounted(() => {
  showedPopup.value = false
  viewer?.destroy()
})
</script>

<template>
  <div ref="container" class="block-viewer">
    <StoryPopup ref="popup" @close="onClose" v-if="showedPopup"  v-slot="{hide}">
      <div class="block-viewer__container" v-if="blockComponent">
        {{blockProps}}
        <component :is="blockComponent!" v-bind="blockProps"/>
      </div>
    </StoryPopup>
  </div>
</template>

<style scoped lang="scss">
.block-viewer {
  &__container {
  }

}
</style>
